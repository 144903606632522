import { Resource, Resource_Type_Enum } from "../types/generated/gql";

const MEDIA_CDN_DOMAIN = process.env.REACT_APP_MEDIA_CDN_DOMAIN;

export function getResourceUrl(resource: Resource): string {
  if (resource.type === Resource_Type_Enum.Video) {
    return `${MEDIA_CDN_DOMAIN}/videos/${resource.id}/playlist.m3u8`;
  }
  if (resource.type === Resource_Type_Enum.Image) {
    return `${MEDIA_CDN_DOMAIN}/images/${resource.id}/original.jpg`;
  }
  throw new Error("Resource type not supported");
};

export function getTranscriptUrl(resource: Resource): string {
  if (resource.type === Resource_Type_Enum.Video) {
    return `${MEDIA_CDN_DOMAIN}/videos/${resource.id}/transcript.json`;
  } else {
    throw new Error("Resource type does not have transcripts")
  }
}
