import React from "react";
import {
  Create,
  FileInput,
  ImageField,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";

enum StoryType {
  text = "TEXT",
  video = "VIDEO",
}

export const StoryCreate = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [storyType, setStoryType] = React.useState<StoryType | null>(null);

  const onSuccess = (data: any) => {
    notify("New Story created");
    redirect("/stories?refresh=true");
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <TextInput
          source="title"
          label="Title"
          fullWidth
          validate={[required()]}
        />
        <SelectInput
          source="storyType"
          choices={[
            {
              id: "TEXT",
              name: "Text",
            },
            {
              id: "VIDEO",
              name: "Video",
            },
          ]}
          validate={[required()]}
          value={storyType}
          onChange={(event) => setStoryType(event.target.value as StoryType)}
        />
        <TextInput multiline source="caption" fullWidth />
        <TextInput multiline source="content" fullWidth />
        <TextInput source="email" validate={[required()]} />

        <TextInput
          multiline
          source="prompt1"
          label="Introduce yourself and what you are going to talk about"
          fullWidth
        />
        <TextInput
          multiline
          source="prompt2"
          label="Describe how it all began and the emotions that you felt"
          fullWidth
        />
        <TextInput
          multiline
          source="prompt3"
          label="Describe what you did to get out of that situation and improve things"
          fullWidth
        />
        <TextInput
          multiline
          source="prompt4"
          label="Describe who you are now and highlight a positive learning"
          fullWidth
        />

        {storyType === "TEXT" && (
          <FileInput
            source="file"
            label="Image"
            accept="image/*"
            validate={[required()]}
          >
            <ImageField source="src" title="title" />
          </FileInput>
        )}

        {storyType === "VIDEO" && (
          <FileInput
            source="file"
            label="Video"
            accept="video/*"
            validate={[required()]}
          >
            <ImageField source="src" title="title" />
          </FileInput>
        )}
      </SimpleForm>
    </Create>
  );
};
