import axios from "axios";
import { ApolloClient } from "@apollo/client";
import { isEmpty } from "ramda";
import { v4 as uuidv4 } from "uuid";

import {
  AddInfoTileDocument,
  AddKeywordDocument,
  AddTagDocument,
  CreateStoryDocument,
  GetInfoTileS3UploadUrlDocument,
  GetSignedUrlDocument,
  GetUserIdByEmailDocument,
  SendEmailDocument,
} from "../types/generated/gql";
import { Params } from "./";
import { changePasswordRequest, register } from "../utils/auth/account";

async function handleStories<T>(
  client: ApolloClient<T>,
  _resource: string,
  params: Params
) {
  const {
    title,
    caption,
    content,
    storyType,
    email,
    prompt1,
    prompt2,
    prompt3,
    prompt4,
  } = params.data;

  if (!email) throw new Error("User email is required");

  const userByEmailResult = await client.query({
    query: GetUserIdByEmailDocument,
    variables: {
      email,
    },
  });

  let userId = userByEmailResult.data.user[0]?.id;

  if (!userId) {
    // Create account
    await register(email, uuidv4());
    await changePasswordRequest(email);

    client.mutate({
      mutation: SendEmailDocument,
      variables: { template: "welcome", email, tags: "" },
    });

    const userByEmailResult = await client.query({
      query: GetUserIdByEmailDocument,
      variables: {
        email,
      },
    });

    userId = userByEmailResult.data.user[0]?.id;
  }

  // Get signed url to upload to
  const getUrlresponse = await client.query({
    query: GetSignedUrlDocument,
    variables: {
      type: storyType === "VIDEO" ? "video" : "image",
    },
  });
  const uploadUrl = getUrlresponse.data.generateSignedUrl.signed_url;
  const resourceId = getUrlresponse.data.generateSignedUrl.resource_id;

  const file = params.data.file.rawFile as File;

  if (!file) throw new Error("An image or video is required");

  // Upload the image or video
  await axios({
    url: uploadUrl,
    method: "put",
    headers: {
      "x-amz-acl": "public-read",
    },
    data: file,
    withCredentials: false, // dont include credentials when uploading to s3, causes CORS issues
    // "header in the response must not be the wildcard '*' when the request's credentials mode is 'include'"
  });

  const resourceUrl = uploadUrl.split("?")[0];
  const storyId = uuidv4();

  let replies = [];

  prompt1 &&
    replies.push({
      story_id: storyId,
      prompt_id: "3e81a1e0-33ed-462f-8339-8c8f8364fe32",
      content: prompt1,
    });

  prompt2 &&
    replies.push({
      story_id: storyId,
      prompt_id: "4684e3c0-5311-42ef-ae6f-32e9bd248988",
      content: prompt2,
    });

  prompt3 &&
    replies.push({
      story_id: storyId,
      prompt_id: "af837599-e0e4-4f4e-b460-70f14b139005",
      content: prompt3,
    });

  prompt4 &&
    replies.push({
      story_id: storyId,
      prompt_id: "7967207b-8e06-4354-8f97-c874b22f1d6a",
      content: prompt4,
    });

  const variables = {
    story: {
      id: storyId,
      user_id: userId,
      title,
      caption,
      content,
      type: storyType, // storyType: "TEXT", "VIDEO", "AUDIO"
      status: "SUBMITTED",
    },
    replies,
    resource: {
      id: resourceId,
      url: resourceUrl,
      type: storyType === "VIDEO" ? "VIDEO" : "IMAGE", // mediaType: "VIDEO" or "IMAGE"
      story_id: storyId,
    },
  };

  console.debug("variables: ", variables);

  const result = await client.mutate({
    mutation: CreateStoryDocument,
    variables,
    refetchQueries: ["Stories"],
  });

  const data = result.data.story;

  return {
    data,
  };
}

async function handleInfoTiles<T>(
  client: ApolloClient<T>,
  _resource: string,
  params: Params
) {
  // Get signed url to upload to
  const getUrlresponse = await client.query({
    query: GetInfoTileS3UploadUrlDocument,
  });
  const uploadUrl = getUrlresponse.data.getInfoTileUploadUrl.url;

  const { accessibility_text, status, action_url } = params.data;
  const file = params.data.file.rawFile as File;

  if (!file) throw new Error("An infotile image is required");
  // Upload the image
  await axios({
    url: uploadUrl,
    method: "put",
    headers: {
      "x-amz-acl": "public-read",
    },
    data: file,
    withCredentials: false, // dont include credentials when uploading to s3, causes CORS issues
    // "header in the response must not be the wildcard '*' when the request's credentials mode is 'include'"
  });

  const imageUrl = uploadUrl.split("?")[0];

  const result = await client.mutate({
    mutation: AddInfoTileDocument,
    variables: {
      object: {
        accessibility_text,
        image_url: imageUrl,
        status,
        action_url,
      },
    },
    refetchQueries: ["InfoTiles"],
  });

  const data = result.data.insert_info_tile_one;

  return {
    data,
  };
}

async function handleKeywords<T>(
  client: ApolloClient<T>,
  _resource: string,
  params: Params
) {
  const keywordId = uuidv4();
  const { referrals, topics, sentiment, value } = params.data;

  const keywordReferrals = referrals
    ? referrals.map((referralId: string) => ({
        keyword_id: keywordId,
        referral_id: referralId,
      }))
    : [];

  const keywordTopics = topics
    ? topics.map((topicId: string) => ({
        keyword_id: keywordId,
        topic_id: topicId,
      }))
    : [];

  const result = await client.mutate({
    mutation: AddKeywordDocument,
    variables: {
      keywordId,
      value,
      sentimentId: sentiment,
      keywordReferrals,
      keywordTopics,
    },
  });

  const data = result.data.insert_keyword_one;

  return {
    data,
  };
}

async function handleTags<T>(
  client: ApolloClient<T>,
  _resource: string,
  params: Params
) {
  const tagId = uuidv4();
  const { type, verified, name } = params.data;

  const result = await client.mutate({
    mutation: AddTagDocument,
    variables: {
      object: {
        id: tagId,
        name,
        type,
        verified: true,
      },
    },
  });

  const data = result.data.insert_tag_one;

  return {
    data,
  };
}

export default function <T>(client: ApolloClient<T>) {
  return (resource: string, params: Params) => {
    switch (resource) {
      case "infotiles":
        return handleInfoTiles(client, resource, params);
      case "keywords":
        return handleKeywords(client, resource, params);
      case "tags":
        return handleTags(client, resource, params);
      case "stories":
        return handleStories(client, resource, params);
    }

    throw new Error(`resource ${resource} was not handled`);
  };
}
