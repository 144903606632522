import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  SearchInput,
  SelectInput,
  TextField,
  useNotify,
  useRedirect,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { Drawer } from "@material-ui/core";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import DrawerContent from "../../components/DrawerContent";
import List from "../../components/List";
import OrderDropdown from "./partials/OrderDropdown";
import BulkDeleteButton, {
  DeleteButtonType,
} from "../../components/BulkDeleteButton";
import { StoryDetail } from "./story.detail";

const StoryFilter = (props: any) => {
  const { resource } = props;
  const choices =
    resource === "violations"
      ? [
          {
            id: "PUBLISHED",
            name: "PUBLISHED",
          },
          {
            id: "QUARANTINED",
            name: "QUARANTINED",
          },
        ]
      : [
          {
            id: "DRAFT",
            name: "DRAFT",
          },
          {
            id: "SUBMITTED",
            name: "SUBMITTED",
          },
          {
            id: "APPROVED",
            name: "APPROVED",
          },
          {
            id: "PUBLISHED",
            name: "PUBLISHED",
          },
          {
            id: "PAUSED",
            name: "PAUSED",
          },

          {
            id: "QUARANTINED",
            name: "QUARANTINED",
          },
        ];

  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <SelectInput source="status" choices={choices} />
    </Filter>
  );
};

const BulkActionButtons = (props: any) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  return (
    <React.Fragment>
      <BulkDeleteButton
        type={DeleteButtonType.story}
        onDeleted={() => {
          notify("Stories deleted");
          redirect("/stories");
          refresh();
          unselectAll("stories");
        }}
        {...props}
      />
    </React.Fragment>
  );
};

export const _StoryList = (props: any) => {
  const refresh = useRefresh();

  const { resource } = props;
  const isModeration = resource === "stories";
  const isViolations = resource === "violations";

  const [orderDropdownOpen, setOrderDropdownOpen] = React.useState(false);

  return (
    <React.Fragment>
      <List
        filters={<StoryFilter />}
        {...props}
        sort={{
          field: isViolations ? "last_reported_at" : "created_at",
          order: "DESC_NULLS_LAST",
        }}
        bulkActionButtons={<BulkActionButtons />}
      >
        <Datagrid rowClick={orderDropdownOpen ? undefined : "edit"}>
          <DateField label="Date Added" source="created_at" />
          {/* <DateField label="First Submitted" source="first_submitted_at" /> */}
          <TextField label="Title" source="title" />
          <TextField label="Email" source="user.account.email" />
          {/* {isModeration && (
            <TextField label="Screener Score" source="user.screener_score" />
          )} */}
          <TextField label="Status" source="status" />
          <TextField label="Type" source="type" />
          <DateField label="Last Submitted" source="last_submitted_at" />
          {/* {isModeration && (
            <TextField label="Approvals" source="number_of_approvals" />
          )} */}
          {/* {isModeration && (
            <DateField label="Last Approved" source="last_approved_at" />
          )} */}
          {/* {isModeration && (
            <TextField label="Rejections" source="number_of_rejections" />
          )}
          {isModeration && (
            <DateField label="Last Rejected" source="last_rejected_at" />
          )} */}
          {/* REMINDER: React.Fragment doesn't work for wrapping these, react-admin treats them as an element? */}
          {isViolations && (
            <TextField
              label="Reported violations"
              source="story_violations_aggregate.aggregate.count"
            />
          )}
          {isViolations && (
            <DateField label="Last reported at" source="last_reported_at" />
          )}
          <OrderDropdown
            label="Order"
            source="order"
            onOpen={() => setOrderDropdownOpen(true)}
            onClose={() => setOrderDropdownOpen(false)}
            {...props}
          />
        </Datagrid>
      </List>
      <Route path={`/${resource}/:id`}>
        {({ match }) => {
          return (
            <Drawer
              open={!!match}
              anchor="right"
              onClose={() => {
                props.push(`/${resource}`);
                refresh();
              }}
            >
              {match && (
                <DrawerContent
                  onClose={() => {
                    props.push(`/${resource}`);
                    refresh();
                  }}
                >
                  <StoryDetail
                    id={match.params.id}
                    onCancel={() => {
                      props.push(`/${resource}`);
                      refresh();
                    }}
                    {...props}
                  />
                </DrawerContent>
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
};

export const StoryList = connect(undefined, { push })(_StoryList);
