import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Labeled, Loading } from "react-admin";

import decorateContent from "../methods/decorateContent";
import {
  Components_Stories_GetKeywordValuesQuery,
  Story,
  useComponents_Stories_GetKeywordValuesQuery,
} from "../../../types/generated/gql";

type ContentProps = {
  content: string;
  keywords: Components_Stories_GetKeywordValuesQuery["keyword"];
};

function Content({ content, keywords }: ContentProps) {
  const decoratedContent = decorateContent(content, keywords);

  return (
    <Box>
      <Labeled label="Content">
        <Typography>{decoratedContent}</Typography>
      </Labeled>
    </Box>
  );
}

type Props = {
  record?: Partial<Story>;
};
export default function StoryContent({ record }: Props) {
  const {
    loading,
    error,
    data,
  } = useComponents_Stories_GetKeywordValuesQuery();
  const story = record;

  if (!story) return null;
  if (!story.content) return null;
  if (error) return <Content content={story.content} keywords={[]} />;

  return !error && loading ? (
    <Loading />
  ) : (
    <>
      <Content content={story.content} keywords={data?.keyword || []} />

      {story?.replies?.map((reply) => (
        <Box>
          <Labeled label={reply.prompt.title}>
            <Typography>{reply.content}</Typography>
          </Labeled>
        </Box>
      ))}
    </>
  );
}
