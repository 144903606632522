import FeedbackIcon from "@material-ui/icons/Feedback";
import LabelIcon from "@material-ui/icons/Label";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import React from "react";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import UserIcon from "@material-ui/icons/People";
import WarningIcon from "@material-ui/icons/Warning";
import { Admin, Resource } from "react-admin";
import { ApolloProvider } from "@apollo/client";

import Dashboard from "./views/Dashboard";
import LoginPage from "./views/LoginPage";
import authProvider from "./authProvider";
import dataProvider, { client } from "./dataProvider";
import { FeedbackList } from "./views/feedback/feedback.list";
import { InfoTileCreate, InfoTileEdit, InfoTileList } from "./views/info-tile";
import { KeywordCreate, KeywordList } from "./views/keyword";
import { StoryCreate, StoryList } from "./views/story";
import { TagCreate, TagsList } from "./views/tags";
import { UserEdit, UserList } from "./views/user";

const App = () => (
  <ApolloProvider client={client}>
    <Admin
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
    >
      <Resource name="users" list={UserList} edit={UserEdit} icon={UserIcon} />
      <Resource name="feedbacks" list={FeedbackList} icon={FeedbackIcon} />
      <Resource
        name="stories"
        list={StoryList}
        icon={LibraryBooks}
        create={StoryCreate}
      />
      <Resource name="violations" list={StoryList} icon={WarningIcon} />
      <Resource
        name="infotiles"
        list={InfoTileList}
        create={InfoTileCreate}
        edit={InfoTileEdit}
        icon={PhotoLibraryIcon}
      />
      <Resource
        name="keywords"
        list={KeywordList}
        create={KeywordCreate}
        icon={SpellcheckIcon}
      />
      <Resource
        name="tags"
        list={TagsList}
        icon={LabelIcon}
        create={TagCreate}
      />
    </Admin>
  </ApolloProvider>
);
export default App;
