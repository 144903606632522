import { ApolloClient } from "@apollo/client";

import { Params } from "./";
import {
  UpdateAccountAccountRolesDocument,
  UpdateAccountAccountRolesMutationVariables,
  UpdateInfoTileDocument,
  UpdateInfoTileMutationVariables,
  UpdateTagDocument,
  UpdateTagMutationVariables,
  UpdateUserDocument,
  UpdateUserMutationVariables,
} from "../types/generated/gql";

async function updateUser<T>(client: ApolloClient<T>, params: Params) {
  const response = await client.mutate({
    mutation: UpdateUserDocument,
    variables: {
      id: params.id,
      user: {
        display_name: params.data.display_name,
      },
      account: {
        active: params.data.account.active,
      },
    } as UpdateUserMutationVariables,
  });

  return {
    data: response.data,
  };
}

async function updateTag<T>(client: ApolloClient<T>, params: Params) {
  const response = await client.mutate({
    mutation: UpdateTagDocument,
    variables: {
      id: params.id,
      tag: {
        name: params.data.name,
        type: params.data.type,
      },
    } as UpdateTagMutationVariables,
  });

  return {
    data: response.data.update_tag_by_pk,
  };
}

function getUpsertData<T>(account_roles: Array<T>, account_id: string) {
  return account_roles.map((obj: any) => ({
    role: obj.role as string,
    account_id: account_id,
  }));
}

async function updateRoles<T>(client: ApolloClient<T>, params: Params) {
  const accountId = params.data.account.id as string;
  const objects = getUpsertData(
    params.data.account.account_roles as Array<any>,
    accountId
  );
  const desiredRoles = objects.map((obj) => obj.role);

  const response = await client.mutate({
    mutation: UpdateAccountAccountRolesDocument,
    variables: {
      objects: objects,
      accountId: accountId,
      roles: desiredRoles,
    } as UpdateAccountAccountRolesMutationVariables,
  });

  return {
    data: response.data,
  };
}

async function handleUsers<T>(
  client: ApolloClient<T>,
  _resource: string,
  params: Params
) {
  const result = await updateUser(client, params);
  await updateRoles(client, params);

  return result;
}

async function handleTags<T>(
  client: ApolloClient<T>,
  _resource: string,
  params: Params
) {
  const result = await updateTag(client, params);

  return result;
}

async function updateInfoTiles<T>(client: ApolloClient<T>, params: Params) {
  const response = await client.mutate({
    mutation: UpdateInfoTileDocument,
    variables: {
      id: params.id,
      infoTile: {
        action_url: params.data.action_url,
        accessibility_text: params.data.accessibility_text,
        status: params.data.status,
      },
    } as UpdateInfoTileMutationVariables,
  });

  return {
    data: { id: response.data.update_info_tile_by_pk.id },
  };
}

export default function <T>(client: ApolloClient<T>) {
  return (resource: string, params: Params) => {
    switch (resource) {
      case "users":
        return handleUsers(client, resource, params);
      case "infotiles":
        return updateInfoTiles(client, params);
      case "tags":
        return updateTag(client, params);
    }

    throw new Error(`resource ${resource} was not handled`);
  };
}
