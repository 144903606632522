import { AxiosError } from "axios";

import store from "./store";
import { get, post } from "./api";

// default refresh interval, used when
const REFRESH_INTERVAL_MS = 600;

type CredentialsResponse = TokenData | MfaData;

type TokenData = {
  jwt_token: string;
  jwt_expires_in: string;
};

type MfaTicket = string;

type MfaData = {
  mfa: true;
  ticket: MfaTicket;
};

async function refreshToken() {
  const { jwt_token, jwt_expires_in } = (await get(
    "/auth/token/refresh",
    store.token
  )) as TokenData;

  store.setToken(jwt_token);

  return parseInt(jwt_expires_in);
}

async function endlessRefreshToken(interval: number) {
  try {
    let innerInterval = interval;

    const timeout = setTimeout(async () => {
      innerInterval = await refreshToken();
      endlessRefreshToken(innerInterval);
    }, interval);

    store.timeout = timeout;

    return timeout;
  } catch (e) {
    stopRefreshingToken();
    store.clearToken();

    throw e;
  }
}

export function startEndlessRefreshToken() {
  endlessRefreshToken(0);
}

function stopRefreshingToken() {
  const timeout = store.timeout;

  if (timeout) {
    clearTimeout(timeout);
    store.timeout = undefined;
  }
}

export async function initializeSession() {
  const interval = await refreshToken();
  endlessRefreshToken(interval);
}

function handleToken({ jwt_token, jwt_expires_in }: TokenData) {
  store.setToken(jwt_token);
  const interval = parseInt(jwt_expires_in) || REFRESH_INTERVAL_MS;
  endlessRefreshToken(interval);
}

async function handleMfaTicket(ticket: MfaTicket, mfaToken: string) {
  return (await post("/auth/mfa/totp", {
    ticket,
    code: mfaToken,
  })) as TokenData;
}

export async function logIn(
  email: string,
  password: string,
  mfaToken?: string
) {
  try {
    const data = (await post("/auth/login", {
      email,
      password,
    })) as CredentialsResponse;

    if ((data as MfaData).mfa) {
      if (!mfaToken) throw new Error("MFA token is not set");

      const innerData = await handleMfaTicket(
        (data as MfaData).ticket,
        mfaToken
      );
      return handleToken(innerData as TokenData);
    } else {
      return handleToken(data as TokenData);
    }
  } catch (err) {
    if ((err as AxiosError).response) {
      throw new Error(
        `${err.response.data.error}: ${err.response.data.message}`
      );
    } else {
      throw new Error(err);
    }
  }
}

export async function logOut(): Promise<unknown> {
  const data = await post(`/auth/logout`, {});
  stopRefreshingToken();
  store.clearToken();
  return data;
}
