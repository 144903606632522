import { ApolloClient } from "@apollo/client";

import {
  GetFeedbackDocument,
  GetInfoTileDocument,
  GetKeywordDocument,
  GetStoryDocument,
  GetTagDocument,
  GetUserDocument,
} from "../types/generated/gql";
import { Params } from "./";

async function handleModeration<T>(
  client: ApolloClient<T>,
  _resource: string,
  id: number
) {
  const response = await client.query({
    query: GetStoryDocument,
    variables: {
      StoryId: id,
    },
  });

  const story = response.data.story[0];

  return {
    data: story,
  };
}

async function handleViolations<T>(
  client: ApolloClient<T>,
  _resource: string,
  id: number
) {
  const response = await client.query({
    query: GetStoryDocument,
    variables: {
      StoryId: id,
    },
  });

  const story = response.data.story[0];

  return {
    data: story,
  };
}

async function handleUsers<T>(
  client: ApolloClient<T>,
  _resource: string,
  id: number
) {
  const response = await client.query({
    query: GetUserDocument,
    variables: {
      UserId: id,
    },
  });

  const user = response.data.user[0];

  return {
    data: user,
  };
}

async function handleInfoTiles<T>(
  client: ApolloClient<T>,
  _resource: string,
  id: number
) {
  const response = await client.query({
    query: GetInfoTileDocument,
    variables: {
      InfoTileId: id,
    },
  });

  const tile = response.data.info_tile[0];

  return {
    data: tile,
  };
}

async function handleFeedbacks<T>(
  client: ApolloClient<T>,
  _resource: string,
  id: number
) {
  const response = await client.query({
    query: GetFeedbackDocument,
    variables: {
      FeedbackId: id,
    },
  });
  const feedback = response.data.feedback[0];

  return {
    data: feedback,
  };
}

async function handleKeywords<T>(
  client: ApolloClient<T>,
  _resource: string,
  id: number
) {
  const response = await client.query({
    query: GetKeywordDocument,
    variables: {
      KeywordId: id,
    },
  });
  const keyword = response.data.keyword[0];

  return {
    data: keyword,
  };
}

async function handleTags<T>(
  client: ApolloClient<T>,
  _resource: string,
  id: number
) {
  const response = await client.query({
    query: GetTagDocument,
    variables: {
      tagId: id,
    },
  });
  const tag = response.data.tag[0];

  return {
    data: tag,
  };
}

export default function <T>(client: ApolloClient<T>) {
  return (resource: any, params: Params) => {
    const { id } = params;

    switch (resource) {
      case "stories":
        return handleModeration(client, resource, id);
      case "violations":
        return handleViolations(client, resource, id);
      case "users":
        return handleUsers(client, resource, id);
      case "infotiles":
        return handleInfoTiles(client, resource, id);
      case "feedbacks":
        return handleFeedbacks(client, resource, id);
      case "keywords":
        return handleKeywords(client, resource, id);
      case "tags":
        return handleTags(client, resource, id);
    }

    throw new Error(`resource ${resource} was not handled`);
  };
}
